<template>
  <div class="about">
    <div class="swiper-box">
      <swiper :options="swiperOption"
              ref="mySwiper"
              class="swiper-wrapper"
              @slide-change-transition-end="handleClickSlide">
        <swiper-slide v-for="(item, index) in swiperList.items"
                      :key="index"
                      class="swioer-item"
                      swiper-animate-effect="shutter2">
          <div class="swiper-box">
            <img :src="item.imgs?(JSON.parse(item.imgs)[langtype]?JSON.parse(item.imgs)[langtype]:item.img):item.img"
                 alt="" />
            <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
      <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
    </div>
    <div class="about-cen">
      <div class="center">
        <div style="margin-top:40px">
          <p v-html="zhaoshangdaili.detailses?JSON.parse(zhaoshangdaili.detailses)[langtype]:zhaoshangdaili.details"></p>
          <!-- <dl>
            <div class="title-img"
                 :style="{ backgroundImage: 'url(' + advantage + ')' }"></div>
            <dd>
              1、深圳把把智能科技有限公司集研发、生产、销售一体，源头厂家直接供货，没有中间商赚取差价，把最大的利润空间让利给客户；
            </dd>
            <dd>
              2、公司高度重视产品的研发，已投入资金3400万，计划融资3000万用于新品开发、品牌推广、营销渠道搭建等，进一步拓展代理商、经销商、加盟商的销售渠道，增加利润来源。
            </dd>
            <dd>
              3、公司巨资引进国内外顶级的现代化生产线和检测设备，并先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证，拥有卓越的品质、过硬的质量和完善的售后；
            </dd>
            <dd>
              4、公司研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，研发生产的产品始终站在行业的前沿，精品荟萃，种类齐全。
            </dd>
            <div class="title-img"
                 :style="{ backgroundImage: 'url(' + policy + ')' }"></div>
            <dd>
              1、营销指导：对于经销商如何开拓当地市场，如何跟进具体项目，公司提供营销方法培训和具体案例的解剖分析，以及项目落地的跟踪指导，同时总部多渠道、多平台推广及曝光、品牌联合运营营销、项目导入扶持，保证在最短的时间实现盈利。
            </dd>
            <dd>
              2、现场指导：公司为合作伙伴提供现场指导支持，在项目谈判、落地等关键节点供现场指导服务，共同促使项目顺利落地。
            </dd>
            <dd>
              3、技术培训：客户加盟实现先培训后加盟，行业技术在线培训，厂商技术落地扶持等相关技术指导，安全有保障。
            </dd>
            <dd>
              4、价格保护：公司制定销售价格的指导及保护机制，对于工程项目提供报备及协调管理机制，统一市场拓展行动。
            </dd>
            <dd>
              5、售后服务：公司建立了严密的服务体系和制度，以“专业、规范、团队、高效”的服务宗旨为客户提供响应及时、解决问题有效、过程规范、内容全面的售后服务。
            </dd>
            <div class="title-img"
                 :style="{ backgroundImage: 'url(' + goal + ')' }"></div>
            <dd>
              我们的目标是创民族品牌、造优质产品、造福社会，3-4年达到上市公司的标准，力争5年时间做成A股上市企业，成为智能制造的高新企业、家喻户晓的知名企业。
              未来，深圳把把智能科技有限公司将始终如一地坚持自主研发路线、贯彻自主创新理念，秉承创造社会价值、实现自我增值的经营理念，以满足客户需求为己任、以开拓、创新、创造为宗旨，以诚信、专业、拼搏、感恩的企业价值观成就行业标杆，为我国各行业安全生产和人民幸福生活保驾护航。
            </dd>
          </dl> -->
        </div>
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + investmentImg + ')' }"></div>
        <div class="agent-form">
          <img src="../../assets/about/cooperation.jpg"
               alt="" />
          <agent-form class="agent-box"></agent-form>
        </div>
        <div class="title-img"
             :style="{ backgroundImage: 'url(' + titleImg + ')' }"></div>
        <div class="qualification-box">
          <img v-for="(item, index) in partnerss"
               :key="index"
               :src="item.img"
               alt="" />
          <!-- <p v-for="(item,index) in partnerss" :key="index">{{item.name}}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgentForm from "./agentForm.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: [],
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  data () {
    return {
      partnerss: [],
      langtype: 0,
      advantage: require("../../assets/about/jm.png"),
      policy: require("../../assets/about/zc.png"),
      goal: require("../../assets/about/wm.png"),
      titleImg: null,
      investmentImg: null,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,

        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [
        // {img: require("../assets/home/57f86325-aa72-4430-840b-85669b889a7a.jpg")},
        // {img: require("../assets/home/718efa0b-9f6d-48dc-a42f-b6ae0b1b63de.jpg")},
        // {img: require("../assets/home/a71f77f4-b971-457f-83c6-e5751782ba02.jpg")},
        // {img: require("../assets/home/ed000602-e323-48e2-b90d-0affc0aeb8f9.jpg")},
      ],
      zhaoshangdaili: {}
    };
  },
  name: "videoPLay",
  components: { AgentForm, swiper, swiperSlide },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    this.investmentImg = require("../../assets/about/title6_" + this.langtype + ".png");
    this.titleImg = require("../../assets/about/title3_" + this.langtype + ".png");
    this.getlist();
  },
  mounted () {
    this.companyInfoList = JSON.parse(localStorage.getItem("companyInfo"));
    setTimeout(() => { }, 1000);
    this.getzhaoshangdaili()
    const _this = this;
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      // this.$wow.init()
    });
  },
  methods: {
    handleClickSlide () {
      // console.log(222);
    },
    async getzhaoshangdaili () {
      let res = await this.api.get(this.apiPath.agentserver, {});
      console.log(res)
      this.zhaoshangdaili = res;
    },
    async getlist () {
      let partners = await this.api.get(this.apiPath.partners, {});
      this.partnerss = partners.items;
      console.log(this.partnerss)

      let res = await this.api.get(this.apiPath.agentSwiper, {});
      this.swiperList = res;
      console.log(this.swiperList)
      if (res.items.length == 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.about-cen {
  padding: 0px 100px;
  // display: flex;
  margin-bottom: 50px;
  background-image: url("../../assets/serve/bh33333.png");
  .center {
    padding: 0 90px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    dt {
      font-size: 16px;
      font-weight: bold;
    }
    .agent-form {
      display: flex;
      .agent-box {
        width: 100%;
      }
      img {
        width: 60%;
        height: 400px;
      }
    }
    .title-img {
      margin: auto;
      margin-bottom: 20px;
      margin-top: 30px;
      width: 220px;
      height: 70px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .description {
      margin-bottom: 20px;
    }
    .img {
      width: 100%;
    }

    .qualification-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      img {
        width: 200px;
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .about-cen {
    padding: 0;
    .center {
      padding: 0 15px;
      width: 100%;
    }
  }
  .agent-form {
    img {
      display: none;
    }
  }
  .tab {
    display: none;
  }
  .qualification-box {
    img {
      width: 48% !important;
    }
  }
  .tab-box1 {
    display: block;
    padding-top: 10px;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        font-size: 16px;
      }
      .active {
        color: #7f0114;
        border-bottom: 1px solid #7f0114;
      }
    }
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
